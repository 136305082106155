import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const packageTypes = ["BASIC", "STANDARD", "PREMIUM"];
const orderStatuses = ["TODO", "IN_PROGRESS"];
const servers = ["ELITE_1", "ELITE_2", "ELITE_3"];
const profileTypes = ["BUSINESS", "PERSONAL"];

export default function ClientPersonalDataPage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [firstName, setFirstName] = useState(
    localStorage.getItem("firstName")
  );
  const [firstNameValid, setFirstNameValid] = useState(false);

  const [lastName, setLastName] = useState(localStorage.getItem("lastName"));
  const [lastNameValid, setLastNameValid] = useState(false);

  const [username, setUsername] = useState(localStorage.getItem("username"));
  const [usernameValid, setUsernameValid] = useState(false);

  const [address, setAddress] = useState(localStorage.getItem("address"));
  const [addressValid, setAddressValid] = useState(false);

  const [country, setCountry] = useState(localStorage.getItem("country"));
  const [countryValid, setCountryValid] = useState(true);

  const [city, setCity] = useState(localStorage.getItem("city"));
  const [cityValid, setCityValid] = useState(false);

  const [phone, setPhone] = useState(localStorage.getItem("phone"));
  const [phoneValid, setPhoneValid] = useState(false);

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    navigate("/business-information")
  };

  const handleBackButtonClick = () => {
    navigate("/about-me-html");
  };
  const handleFirstNameChange = (event) => {
    if (event.target.value) {
      setFirstName(event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      setFirstNameValid(nameValidation(event.target.value));
    } else {
      setFirstNameValid(false);
      setFirstName("");
    }
  };

  const handleLastNameChange = (event) => {
    if (event.target.value) {
      setLastName(event.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      setLastNameValid(nameValidation(event.target.value));
    } else {
      setLastNameValid(false);
      setLastName("");
    }
  };

  const handleUsernameChange = (event) => {
    if (event.target.value) {
      setUsername(removeUsernameUnwantedChars(event.target.value));
      setUsernameValid(usernameValidation(event.target.value));
    } else {
      setUsernameValid(false);
      setUsername("");
    }
  };

  const handleAddressChange = (event) => {
    if (event.target.value) {
      setAddress(event.target.value);
      setAddressValid(true);
    } else {
      setAddressValid(false);
      setAddress("");
    }
  };

  const handleCountryChange = (event) => {
    if (event.target.value) {
      setCountry(event.target.value);
      setCountryValid(true);
    } else {
      setCountryValid(false);
      setCountry("");
    }
  };

  const handleCityChange = (event) => {
    if (event.target.value) {
      setCity(event.target.value);
      setCityValid(true);
    } else {
      setCityValid(false);
      setCity("");
    }
  };

  const handlePhoneChange = (event) => {
    if (event.target.value) {
      setPhone(removePhoneUnwantedChars(event.target.value));
      setPhoneValid(phoneValidation(removePhoneUnwantedChars(event.target.value)));
    } else {
      setPhoneValid(false);
      setPhone("");
    }
  };

  /**------------------------------------------------------------------------------------------
   * VALIDATIONS HERE
   * ------------------------------------------------------------------------------------------*/
  const nameValidation = (name) => {
    const regex = new RegExp("(\\w*)$");
    return regex.test(name);
  };

  const usernameValidation = (username) => {
    const regex = new RegExp("^(\\w*)$");
    return regex.test(username);
  };

  const phoneValidation = (phone) => {
    const regex = new RegExp("[0-9]");
    return regex.test(phone);
  };
  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    firstName
      ? setFirstNameValid(nameValidation(firstName))
      : setFirstNameValid(false);
    lastName
      ? setLastNameValid(nameValidation(lastName))
      : setLastNameValid(false);
    username ? setUsernameValid(usernameValidation(username)) : setUsernameValid(false);
    address ? setAddressValid(true) : setAddressValid(false);
    country ? setCountryValid(true) : setCountryValid(false);
    city ? setCityValid(true) : setCityValid(false);
    phone ? setPhoneValid(phoneValidation(phone)) : setPhoneValid(false);
  }, []);
  useEffect(() => {
    nameValidation(firstName) && localStorage.setItem("firstName", firstName);
  }, [firstName]);

  useEffect(() => {
    nameValidation(lastName) && localStorage.setItem("lastName", lastName);
  }, [lastName]);

  useEffect(() => {
    usernameValidation(username) && localStorage.setItem("username", username);
  }, [username]);

  useEffect(() => {
  localStorage.setItem("address", address);
  }, [address]);

  useEffect(() => {
    localStorage.setItem("country", country);
    }, [country]);

    useEffect(() => {
      localStorage.setItem("city", city);
      }, [city]);

  useEffect(() => {
    phoneValidation(phone) && localStorage.setItem("phone", phone);
  }, [phone]);

  /**------------------------------------------------------------------------------------------
   * AUXILIAR METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const removeSpaces = (string) => {
    return string.split(" ").join("");
  };

  const removeUsernameUnwantedChars = (string) => {
    let tempUsername = string.toLowerCase()
    tempUsername = tempUsername.split(" ").join("")//Remove spaces
    return tempUsername
  };

  const removePhoneUnwantedChars = (string) => {
    let tempPhone = string.split(" ").join("")//Remove spaces
    tempPhone = tempPhone.split("(").join("")//Remove (
      tempPhone = tempPhone.split(")").join("")//Remove )
        tempPhone = tempPhone.split("-").join("")//Remove -
    return tempPhone
  };
  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderFirstNameField = () =>
    firstNameValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("firstName")}
        onChange={handleFirstNameChange}
        value={firstName}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("firstName")}
      onChange={handleFirstNameChange}
      value={firstName}
      error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderLastNameField = () =>
    lastNameValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("lastName")}
        onChange={handleLastNameChange}
        value={lastName}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("lastName")}
      onChange={handleLastNameChange}
      value={lastName}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderUsernameField = () =>
    usernameValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("username")}
        onChange={handleUsernameChange}
        value={username}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("username")}
      onChange={handleUsernameChange}
      value={username}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderAddressField = () =>
    addressValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("address")}
        onChange={handleAddressChange}
        value={address}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("address")}
      onChange={handleAddressChange}
      value={address}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderCountryField = () =>
    countryValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("country")}
        onChange={handleCountryChange}
        value={country}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("country")}
      onChange={handleCountryChange}
      value={country}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderCityField = () =>
    cityValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("city")}
        onChange={handleCityChange}
        value={city}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("city")}
      onChange={handleCityChange}
      value={city}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderPhoneField = () =>
    phoneValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("phone")}
        onChange={handlePhoneChange}
        value={phone}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("phone")}
      onChange={handlePhoneChange}
      value={phone}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("clientPersonalData")}</p>

        <div className="flex flex-col items-center">
          {renderFirstNameField()}
          {renderLastNameField()}
          {renderUsernameField()}
          {renderAddressField()}
          {renderCountryField()}
          {renderCityField()}
          {renderPhoneField()}
        </div>

        <div className="flex flex-row justify-between">
          {firstNameValid &
          lastNameValid &
          usernameValid &
          addressValid &
          countryValid &
          cityValid &
          phoneValid ?          <Button
          variant="contained"
          sx={{ margin: 2 }}
          onClick={handleBackButtonClick}
        >
          {t("back")}
        </Button>:          <Button
            variant="contained"
            sx={{ margin: 2 }}
            onClick={handleBackButtonClick}
            disabled
          >
            {t("back")}
          </Button>}

          {firstNameValid &
          lastNameValid &
          usernameValid &
          addressValid &
          countryValid &
          cityValid &
          phoneValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
