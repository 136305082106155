import { BrowserRouter, Routes, Route } from "react-router-dom";
import AboutMeHTMLPage from "../views/AboutMeHTMLPage";
import AboutMePage from "../views/AboutMePage";
import BusinessInformationPage from "../views/BusinessInformationPage";
import ClientPersonalDataPage from "../views/ClientPersonalDataPage";
import ClientURLAndSocialsPage from "../views/ClientURLAndSocialsPage";
import GeneralInfoPage from "../views/GeneralInfoPage";
import Home from "../views/Home";
import ProfileImagePage from "../views/ProfileImagePage";
import ProfileReadyPage from "../views/ProfileReadyPage";

export default function RoutesComponent(){
    return (
        <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/general-information" element={<GeneralInfoPage/>}/>
            <Route exact path="/client-url-and-socials" element={<ClientURLAndSocialsPage/>}/>
            <Route exact path="/about-me" element={<AboutMePage/>}/>
            <Route exact path="/about-me-html" element={<AboutMeHTMLPage/>}/>
            <Route exact path="/client-personal-data" element={<ClientPersonalDataPage/>}/>
            <Route exact path="/business-information" element={<BusinessInformationPage/>}/>
            <Route exact path="/profile-image" element={<ProfileImagePage/>}/>
            <Route exact path="/profile-ready" element={<ProfileReadyPage/>}/>
        </Routes>
        </BrowserRouter>
    );
};