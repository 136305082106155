import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const packageTypes = ["BASIC", "STANDARD", "PREMIUM"];
const orderStatuses = ["TODO", "IN_PROGRESS"];
const servers = ["ELITE_1", "ELITE_2", "ELITE_3"];
const profileTypes = ["BUSINESS", "PERSONAL"];

export default function ClientURLAndSocialsPage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [clientUrl, setClientURL] = useState(
    localStorage.getItem("client-url")
  );
  const [clientUrlValid, setClientURLValid] = useState(false);

  const [facebook, setFacebook] = useState(localStorage.getItem("facebook"));
  const [facebookValid, setFacebookValid] = useState(true);

  const [twitter, setTwitter] = useState(localStorage.getItem("twitter"));
  const [twitterValid, setTwitterValid] = useState(true);

  const [instagram, setInstagram] = useState(localStorage.getItem("instagram"));
  const [instagramValid, setInstagramValid] = useState(true);

  const [pinterest, setPinterest] = useState(localStorage.getItem("pinterest"));
  const [pinterestValid, setPinterestValid] = useState(true);

  const [youtube, setYoutube] = useState(localStorage.getItem("youtube"));
  const [youtubeValid, setYoutubeValid] = useState(true);

  const [linkedin, setLinkedin] = useState(localStorage.getItem("linkedin"));
  const [linkedinValid, setLinkedinValid] = useState(true);

  const [extraLink1, setExtraLink1] = useState(
    localStorage.getItem("extralink1")
  );
  const [extraLink1Valid, setExtraLink1Valid] = useState(true);

  const [extraLink2, setExtraLink2] = useState(
    localStorage.getItem("extralink2")
  );
  const [extraLink2Valid, setExtraLink2Valid] = useState(true);

  const [extraLink3, setExtraLink3] = useState(
    localStorage.getItem("extralink3")
  );
  const [extraLink3Valid, setExtraLink3Valid] = useState(true);

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    navigate("/about-me")
  };

  const handleBackButtonClick = () => {
    navigate("/general-information");
  };
  const handleClientURLChange = (event) => {
    if (event.target.value) {
      setClientURL(removeSpaces(event.target.value));
      setClientURLValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setClientURLValid(false);
      setClientURL("");
    }
  };

  const handleFacebookChange = (event) => {
    if (event.target.value) {
      setFacebook(removeSpaces(event.target.value));
      setFacebookValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setFacebookValid(false);
      setFacebook("");
    }
  };

  const handleTwitterChange = (event) => {
    if (event.target.value) {
      setTwitter(removeSpaces(event.target.value));
      setTwitterValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setTwitterValid(false);
      setTwitter("");
    }
  };

  const handleInstagramChange = (event) => {
    if (event.target.value) {
      setInstagram(removeSpaces(event.target.value));
      setInstagramValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setInstagramValid(false);
      setInstagram("");
    }
  };

  const handlePinterestChange = (event) => {
    if (event.target.value) {
      setPinterest(removeSpaces(event.target.value));
      setPinterestValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setPinterestValid(false);
      setPinterest("");
    }
  };

  const handleYoutubeChange = (event) => {
    if (event.target.value) {
      setYoutube(removeSpaces(event.target.value));
      setYoutubeValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setYoutubeValid(false);
      setYoutube("");
    }
  };

  const handleLinkedinChange = (event) => {
    if (event.target.value) {
      setLinkedin(removeSpaces(event.target.value));
      setLinkedinValid(urlValidation(removeSpaces(event.target.value)));
    } else {
      setLinkedinValid(false);
      setLinkedin("");
    }
  };

  const handleExtraLink1Change = (event) => {
    if (event.target.value) {
      setExtraLink1(event.target.value);
      setExtraLink1Valid(extraLinkValidation(event.target.value));
    } else {
      setExtraLink1Valid(false);
      setExtraLink1("");
    }
  };

  const handleExtraLink2Change = (event) => {
    if (event.target.value) {
      setExtraLink2(event.target.value);
      setExtraLink2Valid(extraLinkValidation(event.target.value));
    } else {
      setExtraLink2Valid(false);
      setExtraLink2("");
    }
  };

  const handleExtraLink3Change = (event) => {
    if (event.target.value) {
      setExtraLink3(event.target.value);
      setExtraLink3Valid(extraLinkValidation(event.target.value));
    } else {
      setExtraLink3Valid(false);
      setExtraLink3("");
    }
  };

  const keyPressHandler = (event) => {
    var key = event.keyCode;
    if (key === 32) {
      event.preventDefault();
    }
  };

  /**------------------------------------------------------------------------------------------
   * VALIDATIONS HERE
   * ------------------------------------------------------------------------------------------*/
  const extraLinkValidation = (extraLink) => {
    if (extraLink === "none") {
      return true;
    }
    const regex = new RegExp(".+(?<!/)[|].+");
    return regex.test(extraLink);
  };

  const urlValidation = (url) => {
    const regex = new RegExp("(.+)(?<!/)$");
    return regex.test(url);
  };
  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    clientUrl
      ? setClientURLValid(urlValidation(clientUrl))
      : setClientURLValid(false);
    facebook
      ? setFacebookValid(urlValidation(facebook))
      : setFacebookValid(false);
    twitter ? setTwitterValid(urlValidation(twitter)) : setTwitterValid(false);
    instagram ? setInstagramValid(urlValidation(instagram)) : setInstagramValid(false);
    pinterest ? setPinterestValid(urlValidation(pinterest)) : setPinterestValid(false);
    youtube ? setYoutubeValid(urlValidation(youtube)) : setYoutubeValid(false);
    linkedin ? setLinkedinValid(urlValidation(linkedin)) : setLinkedinValid(false);
    extraLink1 ? setExtraLink1Valid(extraLinkValidation(extraLink1)) : setExtraLink1Valid(false);
    extraLink2 ? setExtraLink2Valid(extraLinkValidation(extraLink2)) : setExtraLink2Valid(false);
    extraLink3 ? setExtraLink3Valid(extraLinkValidation(extraLink3)) : setExtraLink3Valid(false);
  }, []);
  useEffect(() => {
    urlValidation(clientUrl) && localStorage.setItem("client-url", clientUrl);
  }, [clientUrl]);

  useEffect(() => {
    urlValidation(facebook) && localStorage.setItem("facebook", facebook);
  }, [facebook]);

  useEffect(() => {
    urlValidation(twitter) && localStorage.setItem("twitter", twitter);
  }, [twitter]);

  useEffect(() => {
    urlValidation(instagram) && localStorage.setItem("instagram", instagram);
  }, [instagram]);

  useEffect(() => {
    urlValidation(pinterest) && localStorage.setItem("pinterest", pinterest);
  }, [pinterest]);

  useEffect(() => {
    urlValidation(pinterest) && localStorage.setItem("pinterest", pinterest);
  }, [pinterest]);

  useEffect(() => {
    urlValidation(youtube) && localStorage.setItem("youtube", youtube);
  }, [youtube]);

  useEffect(() => {
    urlValidation(linkedin) && localStorage.setItem("linkedin", linkedin);
  }, [linkedin]);

  useEffect(() => {
    extraLinkValidation(extraLink1) &&
      localStorage.setItem("extralink1", extraLink1);
  }, [extraLink1]);

  useEffect(() => {
    extraLinkValidation(extraLink2) &&
      localStorage.setItem("extralink2", extraLink2);
  }, [extraLink2]);

  useEffect(() => {
    extraLinkValidation(extraLink3) &&
      localStorage.setItem("extralink3", extraLink3);
  }, [extraLink3]);

  /**------------------------------------------------------------------------------------------
   * AUXILIAR METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const removeSpaces = (string) => {
    return string.split(" ").join("");
  };
  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderClientURLField = () =>
    clientUrlValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("client-url")}
        onChange={handleClientURLChange}
        onKeyDown={keyPressHandler}
        value={clientUrl}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        error
        required
        id=""
        label={t("client-url")}
        onChange={handleClientURLChange}
        onKeyDown={keyPressHandler}
        value={clientUrl}
        helperText={t("client-url-error")}
      />
    );

  const renderFacebookField = () =>
    facebookValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("facebook")}
        onChange={handleFacebookChange}
        value={facebook}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("facebook")}
        onChange={handleFacebookChange}
        value={facebook}
        error
        helperText={t("client-url-error")}
      />
    );

  const renderTwitterField = () =>
    twitterValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("twitter")}
        onChange={handleTwitterChange}
        value={twitter}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("twitter")}
        onChange={handleTwitterChange}
        value={twitter}
        error
        helperText={t("client-url-error")}
      />
    );

  const renderInstagramField = () =>
    instagramValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("instagram")}
        onChange={handleInstagramChange}
        value={instagram}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("instagram")}
        onChange={handleInstagramChange}
        value={instagram}
        error
        helperText={t("client-url-error")}
      />
    );

  const renderPineterestField = () =>
    pinterestValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("pinterest")}
        onChange={handlePinterestChange}
        value={pinterest}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("pinterest")}
        onChange={handlePinterestChange}
        value={pinterest}
        error
        helperText={t("client-url-error")}
      />
    );

  const renderYoutubeField = () =>
    youtubeValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("youtube")}
        onChange={handleYoutubeChange}
        value={youtube}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("youtube")}
        onChange={handleYoutubeChange}
        value={youtube}
        error
        helperText={t("client-url-error")}
      />
    );

  const renderLinkedinField = () =>
    linkedinValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("linkedin")}
        onChange={handleLinkedinChange}
        value={linkedin}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("linkedin")}
        onChange={handleLinkedinChange}
        value={linkedin}
        error
        helperText={t("client-url-error")}
      />
    );

  const renderExtraLink1Field = () =>
    extraLink1Valid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("extralink1")}
        onChange={handleExtraLink1Change}
        value={extraLink1}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("extralink1")}
        onChange={handleExtraLink1Change}
        value={extraLink1}
        error
        helperText={t("extralink-error")}
      />
    );

  const renderExtraLink2Field = () =>
    extraLink2Valid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("extralink2")}
        onChange={handleExtraLink2Change}
        value={extraLink2}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("extralink2")}
        onChange={handleExtraLink2Change}
        value={extraLink2}
        error
        helperText={t("extralink-error")}
      />
    );

  const renderExtraLink3Field = () =>
    extraLink3Valid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("extralink3")}
        onChange={handleExtraLink3Change}
        value={extraLink3}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("extralink3")}
        onChange={handleExtraLink3Change}
        value={extraLink3}
        error
        helperText={t("extralink-error")}
      />
    );

  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("client-url-and-socials")}</p>

        <div className="flex flex-col items-center">
          {renderClientURLField()}
          {renderFacebookField()}
          {renderTwitterField()}
          {renderInstagramField()}
          {renderPineterestField()}
          {renderYoutubeField()}
          {renderLinkedinField()}
          {renderExtraLink1Field()}
          {renderExtraLink2Field()}
          {renderExtraLink3Field()}
        </div>

        <div className="flex flex-row justify-between">
          {clientUrlValid &
          facebookValid &
          twitterValid &
          instagramValid &
          pinterestValid &
          youtubeValid &
          linkedinValid &
          extraLink1Valid &
          extraLink2Valid &
          extraLink3Valid ?          <Button
          variant="contained"
          sx={{ margin: 2 }}
          onClick={handleBackButtonClick}
        >
          {t("back")}
        </Button>:          <Button
            variant="contained"
            sx={{ margin: 2 }}
            onClick={handleBackButtonClick}
            disabled
          >
            {t("back")}
          </Button>}

          {clientUrlValid &
          facebookValid &
          twitterValid &
          instagramValid &
          pinterestValid &
          youtubeValid &
          linkedinValid &
          extraLink1Valid &
          extraLink2Valid &
          extraLink3Valid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
