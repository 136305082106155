import { useTranslation } from "react-i18next";
import Footer from "../../components/Footer";
import TitleBanner from "../../components/TitleBanner";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export default function Home(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleManualProfileClick = () => {
    navigate("/general-information");
  };

  /**----------------------------------------------------------------------------------------------
   * USE STATES HERE
   -----------------------------------------------------------------------------------------------*/
  useState(() => {
    localStorage.setItem("order-no", "");
    localStorage.setItem("fiverr-user", "");
    localStorage.setItem("package-type", "BASIC");
    localStorage.setItem("order-status", "TODO");
    localStorage.setItem("server", "ELITE_1");
    localStorage.setItem("profile-type", "BUSINESS");
    localStorage.setItem("email", "");
    localStorage.setItem("email-password", "");
    localStorage.setItem("email-app-password", "");
    localStorage.setItem("hotmail", "");
    localStorage.setItem("hotmail-password", "");
    localStorage.setItem("hotmail-app-password", "");
    localStorage.setItem("gmail", "");
    localStorage.setItem("gmail-password", "");
    localStorage.setItem("gmail-recovery-email", "");
    localStorage.setItem("profile-password", "");

    localStorage.setItem("client-url", "");
    localStorage.setItem("facebook", "none");
    localStorage.setItem("twitter", "none");
    localStorage.setItem("instagram", "none");
    localStorage.setItem("pinterest", "none");
    localStorage.setItem("youtube", "none");
    localStorage.setItem("linkedin", "none");
    localStorage.setItem("extralink1", "none");
    localStorage.setItem("extralink2", "none");
    localStorage.setItem("extralink3", "none");

    localStorage.setItem("aboutMe", "");
    localStorage.setItem("aboutMeHTML", "");

    localStorage.setItem("firstName", "");
    localStorage.setItem("lastName", "");
    localStorage.setItem("username", "");
    localStorage.setItem("address", "");
    localStorage.setItem("country", "United States");
    localStorage.setItem("city", "");
    localStorage.setItem("phone", "");

    localStorage.setItem("nap", "none");
    localStorage.setItem("businessName", "");
    localStorage.setItem("title", "");
    localStorage.setItem("anchorText", "");

    localStorage.setItem(
      "imageURL",
      `https://blboss.com/munchers/FOXXXXXXXXXXX5.png`
    );
  }, []);
  return (
    <div className="flex flex-col items-center w-screen h-screen justify-center">
      <div className="flex flex-col border-2 border-black w-11/12 h-11/12 items-center">
        <TitleBanner title={t("APP NAME")}></TitleBanner>
        <p className="font-bold my-20">{t("spg-info")}</p>
        <div className="flex flex-row">
          <Button
            onClick={handleManualProfileClick}
            variant="contained"
            sx={{ margin: 2 }}
          >
            {t("manual-profile")}
          </Button>
          <Button variant="contained" sx={{ margin: 2 }}>
            {t("random-profile")}
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
}
