import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function HTMLPreview() {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');
  const [aboutMeHTML,setAboutMeHTML] = useState(localStorage.getItem("aboutMeHTML"))
  const { t, i18n } = useTranslation();

  const handleClickOpen = (scrollType) => () => {

      aboutMeHTML.replace("\">","style=\"color:#FF0000;\"")
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(()=>{
    setAboutMeHTML(aboutMeHTML)
  },[aboutMeHTML])

  return (
    <div>
      <Button onClick={handleClickOpen('paper')} variant="contained">{t("bioHTMLPreview")}</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{t("bioHTMLPreview")}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
            dangerouslySetInnerHTML={{ __html: localStorage.getItem("aboutMeHTML").replace("\">","\" style=\"color:#FF0000;\" target=\"_blank\">",) }}
          >
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}