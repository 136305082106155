import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";

const packageTypes = ["BASIC", "STANDARD", "PREMIUM"];
const orderStatuses = ["TODO", "IN_PROGRESS"];
const servers = ["ELITE_1", "ELITE_2", "ELITE_3"];
const profileTypes = ["BUSINESS", "PERSONAL"];

export default function GeneralInfoPage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  /**OrderNo */
  const [orderNo, setOrderNo] = useState(localStorage.getItem("order-no"));
  const [orderNoValid, setOrderNoValid] = useState();

  /**FiverrUser */
  const [fiverrUser, setFiverrUser] = useState(
    localStorage.getItem("fiverr-user")
  );
  const [fiverrUserValid, setFiverrUserValid] = useState();

  /**PackageType */
  const [packageType, setPackageType] = useState(
    localStorage.getItem("package-type")
  );

  /**OrderStatus */
  const [orderStatus, setorderStatus] = useState(
    localStorage.getItem("order-status")
  );

  /**Server */
  const [server, setServer] = useState(localStorage.getItem("server"));

  /**ProfileType */
  const [profileType, setProfileType] = useState(
    localStorage.getItem("profile-type")
  );

  /**Email */
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [emailValid, setEmailValid] = useState(false);
  const [emailPassword, setEmailPassword] = useState(
    localStorage.getItem("email-password")
  );
  const [emailPasswordValid, setEmailPasswordValid] = useState(false);
  const [emailAppPassword, setEmailAppPassword] = useState(
    localStorage.getItem("email-app-password")
  );
  const [emailAppPasswordValid, setEmailAppPasswordValid] = useState(false);

  /**Hotmail */
  const [hotmail, setHotmail] = useState(localStorage.getItem("hotmail"));
  const [hotmailValid, setHotmailValid] = useState(false);
  const [hotmailPassword, setHotmailPassword] = useState(
    localStorage.getItem("hotmail-password")
  );
  const [hotmailPasswordValid, setHotmailPasswordValid] = useState(false);
  const [hotmailAppPassword, setHotmailAppPassword] = useState(
    localStorage.getItem("hotmail-app-password")
  );
  const [hotmailAppPasswordValid, setHotmailAppPasswordValid] = useState(false);

  /**Gmail */
  const [gmail, setGmail] = useState(localStorage.getItem("gmail"));
  const [gmailValid, setGmailValid] = useState(false);
  const [gmailPassword, setGmailPassword] = useState(
    localStorage.getItem("gmail-password")
  );
  const [gmailPasswordValid, setGmailPasswordValid] = useState(false);
  const [gmailRecoveryEmail, setGmailRecoveryEmail] = useState(
    localStorage.getItem("gmail-recovery-email")
  );
  const [gmailRecoveryEmailValid, setGmailRecoveryEmailValid] = useState(false);

  /**Profile Password */
  const [profilePassword, setProfilePassword] = useState(
    localStorage.getItem("profile-password")
  );
  const [profilePasswordValid, setProfilePasswordValid] = useState();

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleOrderNoChange = (event) => {
    console.log(event.target);
    if (event.target.value) {
      setOrderNo(event.target.value);
      setOrderNoValid(orderNoValidation(event.target.value));
    } else {
      setOrderNoValid(false);
      setOrderNo("");
    }
  };

  const handleFiverrUserChange = (event) => {
    console.log(event.target);
    if (event.target.value) {
      setFiverrUser(event.target.value);
      setFiverrUserValid(orderFiverrUserValidation(event.target.value));
    } else {
      setFiverrUserValid(false);
      setFiverrUser("");
    }
  };

  const handlePackageTypeChange = (event) => {
    setPackageType(event.target.value);
    localStorage.setItem("package-type", event.target.value);
  };

  const handleOrderStatusChange = (event) => {
    setorderStatus(event.target.value);
    localStorage.setItem("order-status", event.target.value);
  };

  const handleServerChange = (event) => {
    setServer(event.target.value);
    localStorage.setItem("server", event.target.value);
  };

  const handleProfileTypeChange = (event) => {
    setProfileType(event.target.value);
    localStorage.setItem("profile-type", event.target.value);
  };

  const handleEmailChange = (event) => {
    if (event.target.value) {
      setEmail(event.target.value);
      setEmailValid(emailValidation(event.target.value));
      localStorage.setItem("email", event.target.value);
    } else {
      setEmailValid(false);
      setEmail("");
    }
  };

  const handleEmailPasswordChange = (event) => {
    if (event.target.value) {
      setEmailPassword(event.target.value);
      setEmailPasswordValid(true);
      localStorage.setItem("email-password", event.target.value);
    } else {
      setEmailPasswordValid(false);
      setEmailPassword("");
    }
  };
  const handleEmailAppPasswordChange = (event) => {
    if (event.target.value) {
      setEmailAppPassword(event.target.value);
      setEmailAppPasswordValid(true);
      localStorage.setItem("email-app-password", event.target.value);
    } else {
      setEmailAppPasswordValid(false);
      setEmailAppPassword("");
    }
  };

  const handleHotmailChange = (event) => {
    if (event.target.value) {
      setHotmail(event.target.value);
      setHotmailValid(emailValidation(event.target.value));
      localStorage.setItem("hotmail", event.target.value);
    } else {
      setHotmailValid(false);
      setHotmail("");
    }
  };

  const handleHotmailPasswordChange = (event) => {
    if (event.target.value) {
      setHotmailPassword(event.target.value);
      setHotmailPasswordValid(true);
      localStorage.setItem("hotmail-password", event.target.value);
    } else {
      setHotmailPasswordValid(false);
      setHotmailPassword("");
    }
  };
  const handleHotmailAppPasswordChange = (event) => {
    if (event.target.value) {
      setHotmailAppPassword(event.target.value);
      setHotmailAppPasswordValid(true);
      localStorage.setItem("hotmail-app-password", event.target.value);
    } else {
      setHotmailAppPasswordValid(false);
      setHotmailAppPassword("");
    }
  };

  /**Gmail Related */
  const handleGmailChange = (event) => {
    if (event.target.value) {
      setGmail(event.target.value);
      setGmailValid(emailValidation(event.target.value));
      localStorage.setItem("gmail", event.target.value);
    } else {
      setGmailValid(false);
      setGmail("");
    }
  };

  const handleGmailPasswordChange = (event) => {
    if (event.target.value) {
      setGmailPassword(event.target.value);
      setGmailPasswordValid(true);
      localStorage.setItem("gmail-password", event.target.value);
    } else {
      setGmailPasswordValid(false);
      setGmailPassword("");
    }
  };
  const handleGmailRecoveryEmailChange = (event) => {
    if (event.target.value) {
      setGmailRecoveryEmail(event.target.value);
      setGmailRecoveryEmailValid(emailValidation(event.target.value));
      localStorage.setItem("gmail-recovery-email", event.target.value);
    } else {
      setGmailRecoveryEmailValid(false);
      setGmailRecoveryEmail("");
    }
  };

  /**Profile Password Related */
  const handleProfilePasswordChange = (event) => {
    if (event.target.value) {
      setProfilePassword(event.target.value);
      setProfilePasswordValid(profilePasswordValidation(event.target.value));
    } else {
      setProfilePasswordValid(false);
      setProfilePassword("");
    }
  };

  const handleNextButtonClick = () => {
    navigate("/client-url-and-socials");
  };

  /**------------------------------------------------------------------------------------------
   * VALIDATIONS HERE
   * ------------------------------------------------------------------------------------------*/
  const orderNoValidation = (orderNo) => {
    const regex = new RegExp("^[A-Z0-9_]*$");
    return regex.test(orderNo);
  };

  const orderFiverrUserValidation = (fiverrUser) => {
    const regex = new RegExp("^[a-z0-9_]*$");
    return regex.test(fiverrUser);
  };

  const emailValidation = (email) => {
    const regex = new RegExp(
      "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
    );
    return regex.test(email);
  };

  const profilePasswordValidation = (profilePassword) => {
    const regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$");
    return regex.test(profilePassword);
  };

  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    orderNo
      ? setOrderNoValid(orderNoValidation(orderNo))
      : setOrderNoValid(false);
    fiverrUser
      ? setFiverrUserValid(orderFiverrUserValidation(fiverrUser))
      : setFiverrUserValid(false);
    packageType ? setPackageType(packageType) : setPackageType(packageTypes[0]);
    orderStatus
      ? setorderStatus(orderStatus)
      : setorderStatus(orderStatuses[0]);
    server ? setServer(server) : setServer(servers[0]);
    profileType ? setProfileType(profileType) : setProfileType(profileTypes[0]);
    email ? setEmailValid(emailValidation(email)) : emailValidation(false);
    emailPassword
      ? setEmailPasswordValid(emailValidation(email))
      : setEmailPasswordValid(false);
    profilePassword
      ? setProfilePasswordValid(profilePasswordValidation(profilePassword))
      : setProfilePasswordValid(false);
  }, []);
  useEffect(() => {
    orderNoValidation(orderNo) && localStorage.setItem("order-no", orderNo);
  }, [orderNo]);

  useEffect(() => {
    orderFiverrUserValidation(fiverrUser) &&
      localStorage.setItem("fiverr-user", fiverrUser);
  }, [fiverrUser]);

  useEffect(() => {
    emailValidation(email) && localStorage.setItem("email", email);
  }, [email]);

  useEffect(() => {
    profilePasswordValidation(profilePassword) &&
      localStorage.setItem("profile-password", profilePassword);
  }, [profilePassword]);

  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderOrderNoField = () =>
    orderNoValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("order-no")}
        onChange={handleOrderNoChange}
        value={orderNo}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        error
        required
        id=""
        label={t("order-no")}
        onChange={handleOrderNoChange}
        value={orderNo}
        helperText={t("order-no-error")}
      />
    );

  const renderFiverrUserField = () =>
    fiverrUserValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("fiverr-user")}
        onChange={handleFiverrUserChange}
        value={fiverrUser}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("fiverr-user")}
        onChange={handleFiverrUserChange}
        value={fiverrUser}
        error
        helperText={t("fiverr-user-error")}
      />
    );

  const renderPackageTypeField = () => (
    <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      id="packageTypeSelector"
      label={t("package-type")}
      select
      value={packageType}
      onChange={handlePackageTypeChange}
    >
      {packageTypes.map((value) => (
        <MenuItem value={value}>{value}</MenuItem>
      ))}
    </TextField>
  );

  const renderOrderStatusField = () => (
    <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      id="orderStatusSelector"
      label={t("order-status")}
      select
      value={orderStatus}
      onChange={handleOrderStatusChange}
    >
      {orderStatuses.map((value) => (
        <MenuItem value={value}>{value}</MenuItem>
      ))}
    </TextField>
  );

  const renderServerField = () => (
    <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      id="serverSelector"
      label={t("server")}
      select
      value={server}
      onChange={handleServerChange}
    >
      {servers.map((value) => (
        <MenuItem value={value}>{value}</MenuItem>
      ))}
    </TextField>
  );

  const renderProfileTypeField = () => (
    <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      id="profileTypeSelector"
      label={t("profile-type")}
      select
      value={profileType}
      onChange={handleProfileTypeChange}
    >
      {profileTypes.map((value) => (
        <MenuItem value={value}>{value}</MenuItem>
      ))}
    </TextField>
  );

  const renderEmailField = () => (
    <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("email")}
      onChange={handleEmailChange}
      value={email}
      {...(!emailValid ? { error: true, helperText: t("email-error") } : {})}
    />
  );

  const renderEmailPasswordField = () =>
    emailPasswordValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("email-password")}
        onChange={handleEmailPasswordChange}
        value={emailPassword}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("email-password")}
        onChange={handleEmailPasswordChange}
        value={emailPassword}
        error
        helperText={t("email-password-error")}
      />
    );

  const renderEmailAppPasswordField = () =>
    emailAppPasswordValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("email-app-password")}
        onChange={handleEmailAppPasswordChange}
        value={emailAppPassword}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("email-app-password")}
        onChange={handleEmailAppPasswordChange}
        value={emailAppPassword}
        error
        helperText={t("email-password-error")}
      />
    );
  const renderHotmailField = () =>
    hotmailValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("hotmail")}
        onChange={handleHotmailChange}
        value={hotmail}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("hotmail")}
        onChange={handleHotmailChange}
        value={hotmail}
        error
        helperText={t("email-error")}
      />
    );

  const renderHotmailPasswordField = () =>
    hotmailPasswordValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("hotmail-password")}
        onChange={handleHotmailPasswordChange}
        value={hotmailPassword}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("hotmail-password")}
        onChange={handleHotmailPasswordChange}
        value={hotmailPassword}
        error
        helperText={t("email-password-error")}
      />
    );

  const renderHotmailAppPasswordField = () =>
    hotmailAppPasswordValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("hotmail-app-password")}
        onChange={handleHotmailAppPasswordChange}
        value={hotmailAppPassword}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("hotmail-app-password")}
        onChange={handleHotmailAppPasswordChange}
        value={hotmailAppPassword}
        error
        helperText={t("email-password-error")}
      />
    );

    /**Gmail Fields Related */
    const renderGmailField = () =>
      gmailValid ? (
        <TextField
          margin="normal"
          sx={{ width: 500 }}
          size="small"
          required
          id=""
          label={t("gmail")}
          onChange={handleGmailChange}
          value={gmail}
        />
      ) : (
        <TextField
          margin="normal"
          sx={{ width: 500 }}
          size="small"
          required
          id=""
          label={t("gmail")}
          onChange={handleGmailChange}
          value={gmail}
          error
          helperText={t("email-error")}
        />
      );
  
    const renderGmailPasswordField = () =>
      gmailPasswordValid ? (
        <TextField
          margin="normal"
          sx={{ width: 500 }}
          size="small"
          required
          id=""
          label={t("gmail-password")}
          onChange={handleGmailPasswordChange}
          value={gmailPassword}
        />
      ) : (
        <TextField
          margin="normal"
          sx={{ width: 500 }}
          size="small"
          required
          id=""
          label={t("gmail-password")}
          onChange={handleGmailPasswordChange}
          value={gmailPassword}
          error
          helperText={t("email-password-error")}
        />
      );
  
    const renderGmailRecoveryEmailField = () =>
      gmailRecoveryEmailValid ? (
        <TextField
          margin="normal"
          sx={{ width: 500 }}
          size="small"
          required
          id=""
          label={t("gmail-recovery-email")}
          onChange={handleGmailRecoveryEmailChange}
          value={gmailRecoveryEmail}
        />
      ) : (
        <TextField
          margin="normal"
          sx={{ width: 500 }}
          size="small"
          required
          id=""
          label={t("gmail-recovery-email")}
          onChange={handleGmailRecoveryEmailChange}
          value={gmailRecoveryEmail}
          error
          helperText={t("email-error")}
        />
      );

  const renderProfilePasswordField = () =>
    profilePasswordValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("profile-password")}
        onChange={handleProfilePasswordChange}
        value={profilePassword}
      />
    ) : (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("profile-password")}
        onChange={handleProfilePasswordChange}
        value={profilePassword}
        error
        helperText={t("profile-password-error")}
      />
    );

  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("general-info")}</p>

        <div className="flex flex-col items-center">
          {renderOrderNoField()}
          {renderFiverrUserField()}
          {renderPackageTypeField()}
          {renderOrderStatusField()}
          {renderServerField()}
          {renderProfileTypeField()}
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderEmailField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderEmailPasswordField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderEmailAppPasswordField()}
            <span class="material-symbols-outlined">help</span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {renderHotmailField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderHotmailPasswordField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderHotmailAppPasswordField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderGmailField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderGmailPasswordField()}
            <span class="material-symbols-outlined">help</span>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {renderGmailRecoveryEmailField()}
            <span class="material-symbols-outlined">help</span>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {renderProfilePasswordField()}
            <span class="material-symbols-outlined">help</span>
          </div>
        </div>

        <div className="flex flex-row justify-end">
          {orderNoValid &
          fiverrUserValid &
          emailValid &
          emailPasswordValid &
          profilePasswordValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
