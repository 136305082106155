import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AboutMeHTMLTools from "./components/AboutMeHTMLTools";
import HTMLPreview from "./components/HTMLPreview";

const packageTypes = ["BASIC", "STANDARD", "PREMIUM"];
const orderStatuses = ["TODO", "IN_PROGRESS"];
const servers = ["ELITE_1", "ELITE_2", "ELITE_3"];
const profileTypes = ["BUSINESS", "PERSONAL"];

export default function AboutMeHTMLPage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [aboutMeHTML, setAboutMeHTML] = useState(
    localStorage.getItem("aboutMeHTML")
  );
  const [aboutMeHTMLValid, setAboutMeHTMLValid] = useState(false);

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    navigate("/client-personal-data")
  };

  const handleBackButtonClick = () => {
    navigate("/about-me");
  };

  const handleAboutMeHTMLChange = (event) => {
    if (event.target.value) {
      setAboutMeHTMLValid(aboutMeHTMLValidation(event.target.value));
      setAboutMeHTML(event.target.value);
    } else {
      setAboutMeHTMLValid(false);
      setAboutMeHTML("");
    }
  };

  /**------------------------------------------------------------------------------------------
   * VALIDATIONS HERE
   * ------------------------------------------------------------------------------------------*/
  const aboutMeHTMLValidation = (url) => {
    const regex = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
    //const regex = new RegExp("[<>]");
    return regex.test(url);
  };
  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    aboutMeHTML
      ? setAboutMeHTMLValid(aboutMeHTMLValidation(aboutMeHTML))
      : setAboutMeHTMLValid(false);
  }, []);

  useEffect(() => {
    aboutMeHTMLValidation(aboutMeHTML) &&
      localStorage.setItem("aboutMeHTML", aboutMeHTML);
  }, [aboutMeHTML]);
  /**------------------------------------------------------------------------------------------
   * AUXILIAR METHODS HERE
   * ------------------------------------------------------------------------------------------*/

  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderAboutMeHTMLField = () =>
    aboutMeHTMLValid ? (
      <TextField
        margin="normal"
        multiline
        rows={20}
        sx={{ width: 500, height: 500 }}
        size="small"
        required
        id=""
        label={t("aboutMeHTML")}
        onChange={handleAboutMeHTMLChange}
        value={aboutMeHTML}
      />
    ) : (
      <TextField
        margin="normal"
        multiline
        rows={20}
        sx={{ width: 500, height: 500 }}
        size="small"
        required
        id=""
        label={t("aboutMeHTML")}
        onChange={handleAboutMeHTMLChange}
        value={aboutMeHTML}
        error
        helperText={t("aboutMeHTMLError")}
      />
    );

  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-fit p-2 my-10">
        <p className="font-bold text-3xl my-5">{t("aboutMeHTML")}</p>

        <div className="flex flex-row justify-center">
          <div className="flex-col">{renderAboutMeHTMLField()}</div>
          <div className="flex-col">
            <AboutMeHTMLTools setAboutMeHTML={setAboutMeHTML} setAboutMeHTMLValid={setAboutMeHTMLValid} aboutMeHTMLValidation={aboutMeHTMLValidation}/>
          </div>
        </div>

        <div className="flex flex-row justify-between">
          {aboutMeHTMLValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleBackButtonClick}
            >
              {t("back")}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleBackButtonClick}
              disabled
            >
              {t("back")}
            </Button>
          )}

          {aboutMeHTMLValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
