import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const packageTypes = ["BASIC", "STANDARD", "PREMIUM"];
const orderStatuses = ["TODO", "IN_PROGRESS"];
const servers = ["ELITE_1", "ELITE_2", "ELITE_3"];
const profileTypes = ["BUSINESS", "PERSONAL"];

export default function BusinessInformationPage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [nap, setNAP] = useState(
    localStorage.getItem("nap")
  );
  const [napValid, setNAPValid] = useState(true);

  const [businessName, setBusinessName] = useState(localStorage.getItem("businessName"));
  const [businessNameValid, setBusinessNameValid] = useState(false);

  const [title, setTitle] = useState(localStorage.getItem("title"));
  const [titleValid, setTitleValid] = useState(false);

  const [anchorText, setAnchorText] = useState(localStorage.getItem("anchorText"));
  const [anchorTextValid, setAnchorTextValid] = useState(false);

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    navigate("/profile-image")
  };

  const handleBackButtonClick = () => {
    navigate("/client-personal-data");
  };
  const handleNapChange = (event) => {
    if (event.target.value) {
      setNAP(event.target.value);
      setNAPValid(true);
    } else {
      setNAPValid(false);
      setNAP("");
    }
  };

  const handleBusinessNameChange = (event) => {
    if (event.target.value) {
      setBusinessName(event.target.value);
      setBusinessNameValid(true);
    } else {
      setBusinessNameValid(false);
      setBusinessName("");
    }
  };

  const handleTitleChange = (event) => {
    if (event.target.value) {
      setTitle(event.target.value);
      setTitleValid(true);
    } else {
      setTitleValid(false);
      setTitle("");
    }
  };

  const handleAnchorTextChange = (event) => {
    if (event.target.value) {
      setAnchorText(event.target.value);
      setAnchorTextValid(true);
    } else {
      setAnchorTextValid(false);
      setAnchorText("");
    }
  };
  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    nap
      ? setNAPValid(true)
      : setNAPValid(false);
    businessName
      ? setBusinessNameValid(true)
      : setBusinessNameValid(false);
    title ? setTitleValid(true) : setTitleValid(false);
    anchorText ? setAnchorTextValid(true) : setAnchorTextValid(false);
  }, []);
  useEffect(() => {
    localStorage.setItem("nap", nap);
  }, [nap]);

  useEffect(() => {
    localStorage.setItem("businessName", businessName);
  }, [businessName]);

  useEffect(() => {
    localStorage.setItem("title", title);
  }, [title]);

  useEffect(() => {
  localStorage.setItem("anchorText", anchorText);
  }, [anchorText]);

  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderNapField = () =>
    napValid ? (
      <TextField
        margin="normal"
        rows={7}
        multiline
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("nap")}
        onChange={handleNapChange}
        value={nap}
      />
    ) : (
      <TextField
      margin="normal"
      rows={7}
        multiline
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("nap")}
      onChange={handleNapChange}
      value={nap}
      error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderBusinessNameField = () =>
    businessNameValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("businessName")}
        onChange={handleBusinessNameChange}
        value={businessName}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("businessName")}
      onChange={handleBusinessNameChange}
      value={businessName}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderTitleField = () =>
    titleValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("title")}
        onChange={handleTitleChange}
        value={title}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("title")}
      onChange={handleTitleChange}
      value={title}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  const renderAnchorTextField = () =>
    anchorTextValid ? (
      <TextField
        margin="normal"
        sx={{ width: 500 }}
        size="small"
        required
        id=""
        label={t("anchorText")}
        onChange={handleAnchorTextChange}
        value={anchorText}
      />
    ) : (
      <TextField
      margin="normal"
      sx={{ width: 500 }}
      size="small"
      required
      id=""
      label={t("anchorText")}
      onChange={handleAnchorTextChange}
      value={anchorText}
        error
        helperText={t("cannotBeEmpty")}
      />
    );

  
  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("businessInformation")}</p>

        <div className="flex flex-col items-center">
          {renderNapField()}
          {renderBusinessNameField()}
          {renderTitleField()}
          {renderAnchorTextField()}
        </div>

        <div className="flex flex-row justify-between">
          {napValid &
          businessNameValid &
          titleValid &
          anchorTextValid  ?          <Button
          variant="contained"
          sx={{ margin: 2 }}
          onClick={handleBackButtonClick}
        >
          {t("back")}
        </Button>:          <Button
            variant="contained"
            sx={{ margin: 2 }}
            onClick={handleBackButtonClick}
            disabled
          >
            {t("back")}
          </Button>}

          {napValid &
          businessNameValid &
          titleValid &
          anchorTextValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
