import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import HTMLPreview from "../HTMLPreview";
import TextField from "@mui/material/TextField";

export default function AboutMeHTMLTools({
  setAboutMeHTML,
  setAboutMeHTMLValid,
  aboutMeHTMLValidation,
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [htmlLinkTag, setHTMLLinkTag] = useState(
    '<a href="https://urlcliente.com">ANCHOR-TEXT</a>'
  );
  const [isCopied, setIsCopied] = useState(false);
  const [anchorText, setAnchorText] = useState("");

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/

  const handleACopyAboutMeClick = () => {
    setAboutMeHTMLValid(aboutMeHTMLValidation(localStorage.getItem("aboutMe")));
    setAboutMeHTML(localStorage.getItem("aboutMe"));
  };

  const handleAnchorTextChange = (event) => {
    setAnchorText(event.target.value);
    setAboutMeHTML(localStorage.getItem("aboutMe").replace(event.target.value,htmlLinkTag
      .replace("ANCHOR-TEXT", anchorText+" ")
      .replace(
        "https://urlcliente.com",
        localStorage.getItem("client-url")
      )))
  };

  return (
    <div className="flex-col">
      <div className="flex-row">
        <Button
          variant="contained"
          sx={{ margin: 2 }}
          onClick={handleACopyAboutMeClick}
        >
          {t("copyAboutMe")}
        </Button>
      </div>

      <div className="flex-row">
        <HTMLPreview aboutMeHTMLValidation={aboutMeHTMLValidation} />
      </div>

      <div className="flex-row border-2 m-2">
        <div className="flex-row p-1">
          <TextField
            margin="normal"
            size="small"
            label="Anchor"
            value={anchorText}
            onChange={handleAnchorTextChange}
          />
        </div>
        <CopyToClipboard
          text={htmlLinkTag
            .replace("ANCHOR-TEXT", anchorText)
            .replace(
              "https://urlcliente.com",
              localStorage.getItem("client-url")
            )}
          onCopy={() => {
            setIsCopied(true);
            setTimeout(() => {
              setIsCopied(false);
            }, 1000);
          }}
        >
          <div className="flex-row">
            <Button variant="contained" sx={{ margin: 2 }}>
              {t("copyLinkTemplate")}
            </Button>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
}
