import { Button } from "@mui/material";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { useTranslation } from "react-i18next";

export default function ExcelExport({ excelData, fileName }) {
  const fileType =
    "aaplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const { t, i18n } = useTranslation();

  const exportToExcel = async () => {
    let rightNow = new Date();
    rightNow = rightNow.toISOString().slice(0, 10).replace(/-/g, "");
    const dateFileName = rightNow + "-" + localStorage.getItem("order-no");
    const excelExportData = [
      {
        order_no: localStorage.getItem("order-no"),
        tipo_paquete: localStorage.getItem("package-type"),
        order_status: localStorage.getItem("order-status"),
        tipo_perfil: localStorage.getItem("profile-type"),
        url: localStorage.getItem("client-url"),
        server: localStorage.getItem("server"),
        email: localStorage.getItem("email"),
        profilePassword: localStorage.getItem("profile-password"),
        title: localStorage.getItem("title"),
        username: localStorage.getItem("username"),
        firstname: localStorage.getItem("firstName"),
        lastname: localStorage.getItem("lastName"),
        business_name: localStorage.getItem("businessName"),
        country: localStorage.getItem("country"),
        address: localStorage.getItem("address"),
        city: localStorage.getItem("city"),
        phone: localStorage.getItem("phone"),
        bio: localStorage.getItem("aboutMe"),
        bio_html: localStorage.getItem("aboutMeHTML"),
        facebook: localStorage.getItem("facebook"),
        twitter: localStorage.getItem("twitter"),
        instagram: localStorage.getItem("instagram"),
        pinterest: localStorage.getItem("pinterest"),
        youtube: localStorage.getItem("youtube"),
        linkedin: localStorage.getItem("linkedin"),
        extralink1: localStorage.getItem("extralink1"),
        ExtraLink2: localStorage.getItem("extralink2"),
        extralink3: localStorage.getItem("extralink3"),
        anchortext: localStorage.getItem("anchorText"),
        nap: localStorage.getItem("nap"),
        image_url: localStorage.getItem("imageURL"),
        hotmail: localStorage.getItem("hotmail"),
        hotmail_pass: localStorage.getItem("hotmail-app-password"),
        yahoo_app_pass: localStorage.getItem("email-app-password"),
        dia: "1",
        mes: "5",
        año: "1986",
        fiverrUser: localStorage.getItem("fiverr-user"),
        mainMail:localStorage.getItem("email"),
        mainMailPass:localStorage.getItem("email-password"),
        mainMailAppPass:localStorage.getItem("email-app-password"),
        hotmailMail:localStorage.getItem("hotmail"),
        hotmailPass:localStorage.getItem("hotmail-password"),
        hotmailAppPass: localStorage.getItem("hotmail-app-password"),
        gmailMail:localStorage.getItem("gmail"),
        gmailPass:localStorage.getItem("gmail-password"),
        gmailRecoveryEmail: localStorage.getItem("gmail-recovery-email")
      },
    ];

    console.log(localStorage.getItem("aboutMeHTML"));

    const ws = XLSX.utils.json_to_sheet(excelExportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, dateFileName + fileExtension);
  };

  return (
    <Button color="success" variant="contained" onClick={(e) => exportToExcel(fileName)} sx={{ margin: 2 }}>
      {t("donwloadFile")}
    </Button>
  );
}
