import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const defaultAvatars = [{name:"Avatar 1",avatarURL:`https://blboss.com/munchers/FOXXXXXXXXXXX1.png`},
{name:"Avatar 2",avatarURL:`https://blboss.com/munchers/FOXXXXXXXXXXX2.png`},
{name:"Avatar 3",avatarURL:`https://blboss.com/munchers/FOXXXXXXXXXXX3.png`},
{name:"Avatar 4",avatarURL:`https://blboss.com/munchers/FOXXXXXXXXXXX4.png`},
{name:"Avatar 5",avatarURL:`https://blboss.com/munchers/FOXXXXXXXXXXX5.png`}];

export default function ProfileImagePage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [imageURL, setImageURL] = useState(localStorage.getItem("imageURL"));
  const [imageURLValid, setImageURLValid] = useState(true);

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    navigate("/profile-ready");
  };

  const handleBackButtonClick = () => {
    navigate("/business-information");
  };
  const handleImageURLChange = (event) => {
    if (event.target.value) {
      setImageURL(event.target.value);
      setImageURLValid(true);
    } else {
      setImageURLValid(false);
      setImageURL("");
    }
  };

  const handleAvatarSelectChange=(event)=>{
    console.log(event.target.value)
    setImageURL(event.target.value)
  }

  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    imageURLValid ? setImageURLValid(true) : setImageURLValid(false);
  }, []);
  useEffect(() => {
    localStorage.setItem("imageURL", imageURL);
  }, [imageURL]);

  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderImageURLField = () => (
    <div className="flex items-center">
      <div className="flex-col">
        {imageURLValid ? (
          <TextField
            margin="normal"
            sx={{ width: 500 }}
            size="small"
            required
            id=""
            label={t("imageURL")}
            onChange={handleImageURLChange}
            value={imageURL}
          />
        ) : (
          <TextField
            margin="normal"
            sx={{ width: 500 }}
            size="small"
            required
            id=""
            label={t("imageURL")}
            onChange={handleImageURLChange}
            value={imageURL}
            error
            helperText={t("cannotBeEmpty")}
          />
        )}
      </div>

      <div className="flex-col m-2">
        <Select
        size="small"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={imageURL}
          label="Age"
          onChange={handleAvatarSelectChange}
        >
          {defaultAvatars.map((avatar)=>
            (<MenuItem value={avatar.avatarURL}>{avatar.name}</MenuItem>)
          )}
        </Select>
      </div>
    </div>
  );

  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("profileImage")}</p>

        <div className="flex flex-col items-center">
          {renderImageURLField()}
          <Avatar
            sx={{ width: 200, height: 200 }}
            alt="Remy Sharp"
            src={imageURL}
          />
        </div>

        <div className="flex flex-row justify-between">
          {imageURLValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleBackButtonClick}
            >
              {t("back")}
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleBackButtonClick}
              disabled
            >
              {t("back")}
            </Button>
          )}

          {imageURLValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
