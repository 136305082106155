import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const packageTypes = ["BASIC", "STANDARD", "PREMIUM"];
const orderStatuses = ["TODO", "IN_PROGRESS"];
const servers = ["ELITE_1", "ELITE_2", "ELITE_3"];
const profileTypes = ["BUSINESS", "PERSONAL"];

export default function AboutMePage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * STATES HERE
   * ------------------------------------------------------------------------------------------*/
  const [aboutMe, setAboutMe] = useState(
    localStorage.getItem("aboutMe")
  );
  const [aboutMeValid, setAboutMeValid] = useState(false);

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    //NAVIGATE PA LA PROXIMA PAGINA
    navigate("/about-me-html")
  };

  const handleBackButtonClick = () => {
    navigate("/client-url-and-socials");
  };

  const handleAboutMeChange = (event) => {
    if (event.target.value) {
      setAboutMeValid(aboutMeValidation(event.target.value));
      setAboutMe(event.target.value)
    } else {
      setAboutMeValid(false);
      setAboutMe("");
    }
  };

  /**------------------------------------------------------------------------------------------
   * VALIDATIONS HERE
   * ------------------------------------------------------------------------------------------*/
  const aboutMeValidation = (url) => {
    //const regex = new RegExp("<(?:\"[^\"]*\"['\"]*|'[^']*'['\"]*|[^'\">])+>");
    const regex = new RegExp("[<>]");
    return !regex.test(url);
  };
  /**------------------------------------------------------------------------------------------
   * USE EFFECTS HERE
   * ------------------------------------------------------------------------------------------*/
  useEffect(() => {
    aboutMe?setAboutMeValid(aboutMeValidation(aboutMe)):setAboutMeValid(false)
  }, []);

  useEffect(() => {
    aboutMeValidation(aboutMe) && localStorage.setItem("aboutMe", aboutMe);
  }, [aboutMe]);
  /**------------------------------------------------------------------------------------------
   * AUXILIAR METHODS HERE
   * ------------------------------------------------------------------------------------------*/

  /**------------------------------------------------------------------------------------------
   * RENDER METHODS HERE
   * ------------------------------------------------------------------------------------------*/
  const renderAboutMeField= () =>
    aboutMeValid ? (
      <TextField
        margin="normal"
        multiline
        rows={20}
        sx={{ width: 500,height:500 }}
        size="small"
        required
        id=""
        label={t("aboutMe")}
        onChange={handleAboutMeChange}
        value={aboutMe}
      />
    ) : (
      <TextField
      margin="normal"
      multiline
      rows={20}
      sx={{ width: 500,height:500}}
      size="small"
      required
      id=""
      label={t("aboutMe")}
      onChange={handleAboutMeChange}
      value={aboutMe}
      error
        helperText={t("aboutMeError")}
      />
    );

  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("aboutMe")}</p>

        <div className="flex flex-col items-center">
          {renderAboutMeField()}
        </div>

        <div className="flex flex-row justify-between">
          {aboutMeValid ?          <Button
          variant="contained"
          sx={{ margin: 2 }}
          onClick={handleBackButtonClick}
        >
          {t("back")}
        </Button>:          <Button
            variant="contained"
            sx={{ margin: 2 }}
            onClick={handleBackButtonClick}
            disabled
          >
            {t("back")}
          </Button>}

          {aboutMeValid ? (
            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleNextButtonClick}
            >
              {t("next")}
            </Button>
          ) : (
            <Button variant="contained" sx={{ margin: 2 }} disabled>
              {t("next")}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
