import TitleBanner from "../../components/TitleBanner";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExcelExport from "../../ExcelExport";

const excelExportData = [
  {name:"juan","bio":`${localStorage.getItem("aboutMeHTML")}`},
  {name:"juan","bio":"pa su madre"}
]


export default function ProfileReadyPage(params) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /**------------------------------------------------------------------------------------------
   * HANDLERS HERE
   * ------------------------------------------------------------------------------------------*/
  const handleNextButtonClick = () => {
    navigate("");
  };

  const handleBackButtonClick = () => {
    navigate("/profile-image");
  };

  const handleDownloadFileClick=()=>{
    const element = document.createElement("a");
    const file = new Blob(
      [`order_no\ttipo_paquete\torder_status\ttipo_perfil\turl\tserver\temail\tpassword\ttitle\tusername\tfirstname\tlastname\tbusiness_name\tcountry\taddress\tcity\tphone\tbio\tbio_html\tfacebook\ttwitter\tinstagram\tpinterest\tyoutube\tlinkedin\textralink1\tExtraLink2\textralink3\tanchortext\tnap\timage_url\thotmail\thotmail_pass\tyahoo_app_pass\tdia\tmes\taño\t\n${localStorage.getItem("order-no")}\t${localStorage.getItem("package-type")}\t${localStorage.getItem("order-status")}\t${localStorage.getItem("profile-type")}\t${localStorage.getItem("client-url")}\t${localStorage.getItem("server")}\t${localStorage.getItem("email")}\t${localStorage.getItem("profile-password")}\t${localStorage.getItem("title")}\t${localStorage.getItem("username")}\t${localStorage.getItem("firstName")}\t${localStorage.getItem("lastName")}\t${localStorage.getItem("businessName")}\t${localStorage.getItem("country")}\t${localStorage.getItem("address")}\t${localStorage.getItem("city")}\t${localStorage.getItem("phone")}\t\"${localStorage.getItem("aboutMe")}\t${localStorage.getItem("aboutMeHTML")}\t${localStorage.getItem("facebook")}\t${localStorage.getItem("twitter")}\t${localStorage.getItem("instagran")}\t${localStorage.getItem("pinterest")}\t${localStorage.getItem("youtube")}\t${localStorage.getItem("linkedin")}\t${localStorage.getItem("extralink1")}\t${localStorage.getItem("extralink2")}\t${localStorage.getItem("extralink3")}\t${localStorage.getItem("anchorText")}\t${localStorage.getItem("nap")}\t${localStorage.getItem("imageURL")}\t${"none"}\t${"none"}\t${localStorage.getItem("email-app-password")}\t`],    
    {type: 'text/plain;charset=utf-8'});
    element.href = URL.createObjectURL(file);
   element.download = "myFile.xls";
   document.body.appendChild(element);
   element.click();
  }
  
  /**------------------------------------------------------------------------------------------
   * RETURN HERE
   * ------------------------------------------------------------------------------------------*/
  return (
    <div className="flex flex-col w-screen items-center">
      <TitleBanner title={t("APP NAME")}></TitleBanner>
      <div className="flex flex-col border-black border-2 w-1/2 my-10">
        <p className="font-bold text-3xl my-5">{t("profileReady")}</p>
        <p className="text-2xl">{t("profileReadyMessage")}</p>

        

        <div className="flex flex-row justify-between">

            <Button
              variant="contained"
              sx={{ margin: 2 }}
              onClick={handleBackButtonClick}
            >
              {t("back")}
            </Button>
          


            <ExcelExport excelData={excelExportData} fileName={"hablamatador"}/>

        </div>
      </div>
      
    </div>
  );
}
