import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangSelector from "../LangSelector";
export default function TitleBanner({ title }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const goToHomePage = ()=>{
    navigate("/")
  };
  return (
    <div style={{    alignItems: "center",color:"white"}} className="bg-heading-blue uppercase h-20 flex justify-between w-full">
      <span onClick={goToHomePage} style={{marginLeft:"20px",cursor:"pointer"}} className="material-symbols-outlined">home</span>
      <p className="text-white font-bold text-3xl self-center w-11/12 cursor-pointer" onClick={goToHomePage}>
        {title}
      </p>
      <div className="w-1/12">
        <LangSelector/>
      </div>
    </div>
  );
}
